import { Template } from '@canalplus/sdk-hodor';
import { useSelector } from 'react-redux';
import { Video } from '../../../../../../components/Video';
import { VideoContent } from '../../../../../../components/Video/types';
import { displayTVModeSelector } from '../../../../../../store/slices/displayMode-selectors';
import Cover from '../../../../../../templates/LandingV5/components/PromotionStrate/components/Cover/Cover';
import { PromotionStateTypes } from '../../../../../../templates/LandingV5/components/PromotionStrate/types';
import styles from './MediaLayer.css';

export type MediaLayerProps = {
  /**
   * The alt of the img
   */
  alt?: string;
  /**
   * If false, only a cover will be displayed
   */
  areAnimationsAutoplay: boolean;
  /**
   * If true, video will played muted
   */
  areAnimationsMuted: boolean;
  /**
   * The type of the promotion strate: cover or banner
   */
  promotionType: PromotionStateTypes;
  /**
   * Used as a uniq id to play the default video only once, until the next refresh of the app
   */
  uniqId?: string;
  /**
   * The image displayed before the video for mobile
   */
  URLImageCompact?: string;
  /**
   * The image displayed before the video for other dives than mobile
   */
  URLImageRegular: string;
  /**
   * The contentId or the url (if no DRM) of the content needed by OnePlayer
   */
  videoContent?: VideoContent;
  /**
   * If true, the video will indefinitely loop
   */
  hasVideoLoop?: boolean;
};

function MediaLayer({
  alt,
  areAnimationsAutoplay,
  areAnimationsMuted,
  hasVideoLoop = false,
  promotionType,
  uniqId,
  URLImageCompact,
  URLImageRegular,
  videoContent,
}: MediaLayerProps): JSX.Element {
  const isTvDevice = useSelector(displayTVModeSelector);

  const cover = (
    <Cover
      alt={alt}
      promotionType={promotionType}
      URLImageRegular={URLImageRegular}
      URLImageCompact={URLImageCompact}
    />
  );

  return (
    <div className={styles.mediaLayer}>
      {areAnimationsAutoplay && videoContent ? (
        <Video
          areBlackBarsHidden={false}
          cover={cover}
          from={Template.Landing}
          intersectionRatio={isTvDevice ? 0.7 : 0.5} // To avoid playing multiple Promotions at least 70% or 50% of the container height has to be on the screen to be played.
          isLoop={hasVideoLoop}
          isMuted={areAnimationsMuted}
          onlyOnceId={uniqId}
          timeBetweenCoversAndPlayer={1000}
          videoContent={videoContent}
        />
      ) : (
        cover
      )}
    </div>
  );
}

export default MediaLayer;
